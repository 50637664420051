















import Vue from 'vue';
import AddDownloadManager from './AddDownloadManager.vue';
import AddPreviouslyCommissionedMakersToNew from './AddPreviouslyCommissionedMakersToNew.vue';

export default Vue.extend({
  components: {
    AddDownloadManager,
    AddPreviouslyCommissionedMakersToNew,
  },
  data() {
    return {
      v: '2021-03',
    };
  },
  computed: {
    d(): string {
      return this.$d(new Date(this.v), 'MONTH_YEAR');
    },
  },
});
